import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import CardHorario from './CardHorario';
import { IMaskInput } from 'react-imask';
import { UserAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ImageMenu from '../../imgwebpack/TesteImagem.webp';

const Atendimento = () => {
    const {HorariosMarcado} = UserAuth()
    const [value, setValue] = useState(new Date());
    const [isVisible, setIsVisible] = useState(false)
    const [isVisibleMessage, setIsVisibleMessage] = useState(false)
   const [nome, setNome] = useState('')
   const [dados, setDado] = useState('')
   const [numero, setNumero] = useState('')
    const [data, setData] = useState('')
    const [showHelp, setShowHelp] = useState(false);

    const handleFocus = () => {
      setShowHelp(true);
    };
  
    const handleBlur = () => {
      if (!numero) {
        setShowHelp(false);
      }
    };
  
    const onChange = async (newValue) => {
        if(!nome || !numero){
        setIsVisibleMessage(true)
        }else{
        const formattedDate = format(newValue, 'dd-MM-yyyy');
        setData(formattedDate);  
        setValue(newValue); 
        const dados = await HorariosMarcado(formattedDate)
        setDado(dados ? dados : null)
        setIsVisibleMessage(false)
        setIsVisible(true)
        }
    };
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 3;
    

    return ( 
    <div className="flex flex-col">
           <header className="flex justify-between rounded-md shadow-md py-2">
            <button onClick={() => window.history.back()}>
                <FontAwesomeIcon icon={faArrowLeft} size='xl' className="w-[50px]"/>  
            </button>
            <img src={ImageMenu} className='w-52 lg:w-80' alt="" />
            <button className="w-[50px]">
                
            </button>
            </header>
        <div className='flex flex-col mx-auto justify-center items-center text-center p-2'>
        <h3 className='text-3xl mt-3 text-green-500'> 📅 </h3> 
           <h3 className='my-2 mb-0 text-xl text-green-500 font-bold'>Bem vindo ao Agendamento de Atendimento</h3> 
           
           <div className='flex flex-col my-1 px-2 lg:w-4/5 py-3 pb-5 '>
                <h4 className='m-2 mb-1 text-lg text-gray-700 text-left'> Nome: </h4>
                <input type="text" placeholder='Seu nome' className='shadow-sm appearance-none border rounded w-full py-3 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline text-md' value={nome} onChange={(e) => setNome(e.target.value)} />
                <h4  className='m-2 mb-1 text-lg  text-gray-700 text-left'> Número de Telefone: </h4>
                {showHelp && (
                <p className='text-sm text-gray-500 mb-0'>
                Digite seu número corretamente pois o atendimento será via Whatsapp
                </p>
      )}        <IMaskInput
                mask="(00) 00000-0000"
                className='shadow-sm appearance-none border rounded w-full py-3 px-3 text-md text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                id="numero"
                value={numero}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setNumero(e.target.value)}
                placeholder="(xx) xxxxx-xxxx"
                />
                {isVisibleMessage && (
                    <div>
                        <h6 className='text-red-500 text-sm mt-4'>Por favor, preencha todas as informações acima corretamente.</h6>
                    </div>
                )}
                <h3 className='mt-4 mb-3 text-xl font-bold text-gray-600'> 🗓️ Selecione a data do seu atendimento</h3>
                <Calendar className="mx-auto"  minDate={new Date(year, 0, 1)} // 1º de janeiro do ano atual
                maxDate={new Date(year, month, 31)} // 31 de dezembro do ano atual
                tileDisabled={({date}) => date.getDay() === 0 || date.getDay() === 6} onChange={onChange} value={value}/>
                {isVisible && (
                <CardHorario message={data} nome={nome} numero={numero} horario={dados} onClose={() => setIsVisible(false)}></CardHorario>
                )}
              
            </div>
        </div>
    </div> );
}

export default Atendimento ;