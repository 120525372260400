import {  useEffect, useState } from "react"
import { UserAuth } from "../../context/AuthContext"
import { addPaymentIdAsaas, getPaymentIdAsaas, getPaymentStartTimeAsaas, removePaymentIdAsaas, removePaymentStartTimeAsaas, setPaymentStartTimeAsaas } from "../../helpers/localStorage.helper"
import {  QRCodeCanvas } from "qrcode.react"
import PagamentoComponent from "../Kiwify/PagamentoComponent"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"
import debounce from 'lodash.debounce'; // Instale essa biblioteca: npm install lodash.debounce
import { Copy, CreditCard, Mail, QrCode, X,Lock } from "lucide-react"

         export default function ComponentPagamentoAsaas({valor, linkexterno, addOrderBump} ) {
     const { criarPagamentoAsaas , cancelarPagamentoAsaas, user, logout} = UserAuth()
     const [payment, setPayment] = useState(getPaymentIdAsaas() || null);
     const [email, setEmail] = useState(``)  
     const navigate = useNavigate()
     const [aviso, setAviso] = useState(null)
     const uid = user.uid
     const [isProcessing, setIsProcessing] = useState(false);
     const [isProcessingPix, setIsProcessingPix] = useState(false);



     const handleCopy = () => {
        navigator.clipboard.writeText(payment.payload).then(() => {
          toast.success('Código copiado, agora efetue o pagamento!');
        }).catch((err) => {
          console.error('Erro ao copiar:', err);
          alert('Erro ao copiar o código. Tente novamente.');
        });
      };
 
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      
      const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);
      };
        

     const handleCriarPagamentoAsaas = async() => {
        if (!email){
          setAviso("Por favor, preencha o campo de e-mail.");
          return
        }
        if (email && !validateEmail(email)) {
          setAviso('E-mail inválido');
          return
        }
        if(!uid){
          toast.error("Sessão expirada. Por favor, faça login novamente.")
          logout()
          return
        }
        if (isProcessing) return;
        // Ativa o estado de processamento
        setIsProcessingPix(true);
        setAviso(null)
        try{
          const dados = await criarPagamentoAsaas(valor, email, uid, addOrderBump)
          addPaymentIdAsaas(dados)
          setPayment(dados)
          setPaymentStartTimeAsaas()
       
        }catch (error) {
          console.error('Erro ao cancelar pagamento:', error);
        } finally {
          // Garante que o estado seja desativado após finalizar
          setIsProcessingPix(false);
        }
     }

  const handleCancelarPagamento = async (id) => {
    // Se já estiver processando, impede nova execução
    if (isProcessing) return;
    // Ativa o estado de processamento
    setIsProcessing(true);
    try {
      // Executa a lógica principal
      await cancelarPagamentoAsaas(id);
      setPayment(null);
      removePaymentIdAsaas();
      removePaymentStartTimeAsaas();
      setEmail(``);
    } catch (error) {
      console.error('Erro ao cancelar pagamento:', error);
    } finally {
      // Garante que o estado seja desativado após finalizar
      setIsProcessing(false);
    }
  };

   const checkPaymentStatus = async (paymentId) => {
    try {
      // Faz a chamada para a rota do backend
      const response = await axios.get(`https://backend-production-22a2.up.railway.app/get-payment-status/${paymentId}`);
      
      if (response.status === 200) {
        const data = response.data;
            console.log(data)
        // Verifica o status retornado do backend
        if (data.status === 'approved') {
          console.log('Pagamento aprovado!');
            handleCancelarPagamento(paymentId);
            setPayment(null);
            removePaymentIdAsaas();
            removePaymentStartTimeAsaas();
            
          navigate('/comprarealizada'); // Exemplo: Redireciona para a página de sucesso
        } else {
          console.log('Status do pagamento:', data);
        }
      } else {
        console.error('Erro ao verificar o status do pagamento. Resposta inesperada:', response.status);
      }
    } catch (error) {
      console.error('Erro ao verificar o status do pagamento:', error.message);
    }
  };



useEffect(() => {
  const debouncedCheckPaymentStatus = debounce(() => {
    if (payment) {
      checkPaymentStatus(payment.id);

      const paymentStartTime = getPaymentStartTimeAsaas();
      const timeElapsed = Date.now() - paymentStartTime;

      if (timeElapsed > 1200000) {
        // Tempo excedido, cancela o pagamento
        handleCancelarPagamento(payment.id);
        setPayment(null);
        removePaymentIdAsaas();
        removePaymentStartTimeAsaas();

        // Notifica o usuário
        toast.error('Pagamento expirado. Inicie um novo pagamento.');
      }
    }
  }, 1000); // Espera 1 segundo para executar após o último evento

  const handlePageFocusOrVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      debouncedCheckPaymentStatus();
    }
  };

  // Adiciona os eventos: 'visibilitychange' e 'focus'
  document.addEventListener('visibilitychange', handlePageFocusOrVisibilityChange);
  window.addEventListener('focus', handlePageFocusOrVisibilityChange);

  return () => {
    // Remove os eventos e cancela o debounce
    document.removeEventListener('visibilitychange', handlePageFocusOrVisibilityChange);
    window.removeEventListener('focus', handlePageFocusOrVisibilityChange);
    debouncedCheckPaymentStatus.cancel(); // Cancela o debounce pendente
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [payment]);
      
       
        return (<>
         
        {payment ? (
          <div className="space-y-6">
            <div>
          
              <label className="block text-sm font-medium text-gray-700 mb-2">Código PIX</label>
             
              <div className="relative">
                <input
                  value={payment.payload}
                  readOnly
                  className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 pr-10"
                />
                <button
                  onClick={handleCopy}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-gray-700"
                >
                  <Copy className="w-5 h-5" />
                </button>
              </div>
             
            </div>

            <div className="flex justify-center">
              <QRCodeCanvas value={payment.payload} size={200} />
            </div>
            <div className="hidden md:flex flex-col justify-center items-center">
  <span className="text-gray-700 text-xs">
    Clique no botão abaixo após o pagamento
  </span>
  <button onClick={() => checkPaymentStatus(payment.id)} className="bg-gray-900 text-white py-2 px-3 my-2 rounded-lg font-normal text-sm hover:bg-gray-800 transition-colors flex items-center justify-center gap-2">
    Já Paguei
  </button>
</div>
            <button
                onClick={ handleCopy}
                className="w-full border bg-gray-900 text-white py-3 my-3 rounded-lg font-medium hover:bg-gray-800 transition-colors flex items-center justify-center gap-2"
            >
              <CreditCard className="w-5 h-5" />
              Copiar Codigo Pix
            </button>
            <button
              className="w-full border border-red-500 hover:bg-red-600 hover:text-white text-red-500 font-semibold py-3 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
              onClick={() => handleCancelarPagamento(payment.id)}
              disabled={isProcessing} // Desabilita o botão enquanto processa
              >
           <X className="w-5 h-5 mr-2" />
      {isProcessing ? 'Cancelando...' : 'Cancelar Pagamento'}
            </button>
          </div>
        ) : (
            <div className="space-y-6">
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-gray-700">
                <Mail className="w-5 h-5" />
                <label htmlFor="email" className="font-medium">
                  Seu Email
                </label>
              </div>
              
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="seuemail@gmail.com"
                className="w-full p-3 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200"
                required
              />
            {aviso && (
             <p className="text-sm text-red-500">{aviso}</p>

            )}
<div className="flex items-center justify-center mt-2 text-sm">
  <p className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">
    Você receberá sua dieta em PDF via email. 📨
  </p>
</div>

            </div>
  
            <button
                onClick={() => handleCriarPagamentoAsaas()}
                className="w-full bg-gray-900 text-white py-3 my-2 rounded-lg font-medium hover:bg-gray-800 transition-colors flex items-center justify-center gap-2"
              disabled={isProcessingPix}
            >
              <QrCode className="w-5 h-5" />
              {isProcessingPix ? 'Gerando...' : ' Gerar Código Pix'}
            </button>

      <div className="flex items-center justify-center text-sm text-gray-600">
        <Lock className="w-4 h-4 mr-2 text-green-600" />
        <span>Pagamento Seguro</span>
      </div>
          
            {linkexterno && valor !== 1.99 &&(
              <div className="flex items-center justify-center text-center">
                <PagamentoComponent linkexterno={linkexterno}></PagamentoComponent>
              </div>
            )}
          </div>
        )}
    
    </>  )
  } 