import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ImageMenu from '../../imgwebpack/TesteImagem.webp';
const Consulta = () => {
    const {HorariosMarcadoCliente, user, HorariosMarcadoClientePersonalizado} = UserAuth()
    const [horarios, setHorariosMarcados] = useState('')
    const [loading, setLoading] = useState(true)
    useEffect( () => {
        const buscarHorarios = async () => {
            const horariosMarcados = await HorariosMarcadoCliente(user.email)
            setHorariosMarcados(horariosMarcados)
            console.log(horariosMarcados)
            if(Object.keys(horariosMarcados).length === 0 ) {
                const horariosMarcadosPersonalizado = await HorariosMarcadoClientePersonalizado(user.email)
                setHorariosMarcados(horariosMarcadosPersonalizado)
                console.log(horariosMarcadosPersonalizado)
            }
            
        }
       buscarHorarios()
    },[user.email, HorariosMarcadoCliente, HorariosMarcadoClientePersonalizado])

    useEffect(() => {
        // Simula um tempo de carregamento de 3 segundos
        const timer = setTimeout(() => {
          setLoading(false);

        }, 1300);
        window.moveTo(0,0)
        // Limpa o timer quando o componente é desmontado
        return () => clearTimeout(timer);
      }, []);

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Janeiro é 0
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
      
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      };
       
    return ( 
        <div>
             {loading ? ( 
            <div className="flex justify-center items-center h-screen">
            <FontAwesomeIcon icon={faSpinner} color='#17A34A' spin size="3x" />
            </div>) : 
             (
        <div>
            <header className="flex justify-between bg-white rounded-md shadow-md py-2">
            <button onClick={() => window.history.back()}>
                <FontAwesomeIcon icon={faArrowLeft} size='xl' className="w-[50px]"/>  
            </button>
            <img src={ImageMenu} className='w-52 lg:w-80' alt="" />
            <button className="w-[50px]">
                
            </button>
            </header>
        <h1 className="text-2xl font-bold text-center my-5 font-sans text-green-500">CONSULTAS MARCADAS</h1>
        {user && <div>
            {Object.keys(horarios).length === 0 && <p className="text-center text-lg md:text-3xl text-gray-600">Nenhum horário marcado.</p>}
            {Object.entries(horarios).map(([data, horariosNaData]) => (
                <div key={data} className="mb-5">
                    <h2 className="text-xl md:text-2xl font-semibold text-gray-800 text-center mb-4">Data: {data}</h2>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 px-4">
                        {Object.entries(horariosNaData).map(([horario, detalhes]) => (
                            <div key={horario} className={`flex flex-col justify-evenly h-[250px] items-center text-center border rounded-lg shadow-md hover:shadow-lg transition-shadow ${detalhes.concluido === true ?  'bg-green-600' : 'bg-white' } ${detalhes.concluido === true ?  'text-white' : 'text-gray-700' } `}>
                                <p className="font-medium md:text-xl m-0 p-2">Horario {horario}</p>
                                <p className="text-sm md:text-lg m-0 p-2">Nome: {detalhes.nome}</p>
                                <p className="text-sm md:text-lg m-0 p-2">Número: <br></br>{detalhes.numero}</p>
                                {detalhes.concluido === true && (
                                <p className='text-sm font-medium md:text-lg'>Data de Conclusão: <span className='font-normal'>{formatDate(detalhes.dataconclusao)}</span></p>)}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>}
    </div> 
    )} 
    </div>  
)

};
    


export default Consulta ;