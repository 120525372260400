import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import ImageMenu from '../../imgwebpack/PerfilMenu-removebg-preview.webp';

// Ícone do menu mais sofisticado
const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="w-8 h-8">
    <line x1="3" y1="12" x2="21" y2="12" className="transition-all duration-300 ease-in-out"></line>
    <line x1="3" y1="6" x2="21" y2="6" className="transition-all duration-300 ease-in-out"></line>
    <line x1="3" y1="18" x2="21" y2="18" className="transition-all duration-300 ease-in-out"></line>
  </svg>
);

const ExitIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
    <polyline points="16 17 21 12 16 7"></polyline>
    <line x1="21" y1="12" x2="9" y2="12"></line>
  </svg>
);

function HamburgerMenu({ isOpen, toggleMenu, tickets }) {
  const { user, logout } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);
  const navigate = useNavigate();
 
  const ContatoSuporte = () => {
    navigate('/suporte')
  }



  const LocalDiet = () => {
    navigate('/dieta')
  }

  const SobreMim = () => {
    navigate('/sobre')
  }
  const Indicar = () => {
    navigate('/IndiqueNutriInteligente')
  }

  useEffect(() => {
    setUserTickets(tickets)
  }, [tickets]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (e) {
      console.error('Logout failed', e);
    }
  };

  return (
    <div className="relative">
      <button 
        className="flex items-center justify-center w-12 h-12 text-green-600 focus:outline-none hover:text-green-700 transition-colors duration-300" 
        onClick={toggleMenu}
      >
        <MenuIcon />
      </button>
      
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed inset-y-0 left-0 w-4/5 md:w-1/2 lg:w-1/3 bg-white shadow-lg transform transition-transform duration-300 ease-in-out">
            <button 
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-3xl font-bold"
              onClick={toggleMenu}
            >
              &times;
            </button>
            
            <nav className="h-full flex flex-col">
              <div className="p-6 bg-white text-center border-b border-gray-200">
                <div className="flex flex-col items-center space-y-4">
                  <div className="rounded-full overflow-hidden">
                    <img src={ImageMenu} className="w-[70px] h-[120px] object-cover" alt="Profile" />
                  </div>
                  <div>
                    <h4 className="text-gray-800 font-semibold">Olá, Cliente 🍀</h4>
                    <p className="text-sm text-gray-600">{user && user.email}</p>
                    <p className="text-sm text-gray-600">Ticket: {userTickets}</p>
                  </div>
                </div>
              </div>
              
              <div className="flex-grow overflow-y-auto">
                <ul className="space-y-2 p-4">
                  <li>
                    <button className="w-full text-left py-2 px-4 rounded hover:bg-gray-100 transition-colors duration-200 text-gray-800" onClick={SobreMim}>
                      <span className="inline-block w-6 mr-2">🍏</span> Perfil
                    </button>
                  </li>
                  <li>
                    <button className="w-full text-left py-2 px-4 rounded hover:bg-gray-100 transition-colors duration-200 text-gray-800" onClick={Indicar}>
                      <span className="inline-block w-6 mr-2">💚</span> Indique e Ganhe
                    </button>
                  </li>
                  <li>
                    <button className="w-full text-left py-2 px-4 rounded hover:bg-gray-100 transition-colors duration-200 text-gray-800" onClick={LocalDiet}>
                      <span className="inline-block w-6 mr-2">🥗</span> Acessar Dieta
                    </button>
                  </li>
                  <li>
                    <button className="w-full text-left py-2 px-4 rounded hover:bg-gray-100 transition-colors duration-200 text-gray-800" onClick={ContatoSuporte}>
                      <span className="inline-block w-6 mr-2">💬</span> Fale conosco
                    </button>
                  </li>
                 
                </ul>
              </div>
              
              <div className="p-4 border-t border-gray-200">
                <button 
                  className="flex items-center w-full py-2 px-4 text-red-600 hover:bg-red-100 rounded transition-colors duration-200" 
                  onClick={handleLogout}
                >
                  <ExitIcon />
                  <span className="ml-2">Sair</span>
                </button>
              </div>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}

export default HamburgerMenu;