import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import NutriImagem from '../../imgwebpack/SignMasc.webp';
import '../Sign/Sign.css';
import { XIcon } from 'lucide-react';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/home');
    } catch (e) {
      setError("Seu endereço de e-mail ou senha está incorreto. Por favor, verifique e tente novamente."); 
    }// Mensagem genérica para outros erros
    };

  const Modal = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">Aviso Importante</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <XIcon size={20} />
          </button>
        </div>
        <div className="mb-4">
          <p className="text-gray-600 text-sm mb-2">O site está passando por manutenção para melhorar sua experiência.</p>
          <p className="text-gray-600 text-sm">Estamos trabalhando para resolver o problema o mais rápido possível.</p>
        </div>
        <div className="bg-green-50 border-l-4 border-[#17a34a] p-3 mb-4">
          <p className="text-[#17a34a] text-sm font-semibold">Previsão para normalização: Segunda</p>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-[#17a34a] text-white py-2 px-4 rounded-md hover:bg-[#15803d] transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#17a34a] focus:ring-opacity-50 text-sm"
        >
          Entendi
        </button>
      </div>
    </div>
  );

  return (
    <div className="signin-container">
      <div className="signin-card">
        <div className="text-center">
          <img src={NutriImagem} alt="Nutrimage" className="logo-image" />
          <h2 className="signin-heading font-bold">Entre com sua Conta</h2>
        </div>
        <br />
        <form onSubmit={handleSubmit} className="signin-form">
          <div className="form-group">
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              type="email"
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              autoComplete="current-password"
              type="password"
              placeholder="Senha"
              required
            />
          </div>
          <br />
          {error && <p className="error-message">{error}</p>}
          <button className="signin-button">ENTRAR</button>
        </form>
        <div className="signin-links">
          <p>
            Esqueceu sua senha?{' '}
            <Link to="/redefinirsenha" className="forgot-password-link">
              REDEFINIR
            </Link>
          </p>
          <Link to="/signup" className="signup-link">
            <strong>CRIAR UMA CONTA</strong>
          </Link>
        </div>
      </div>
      {showModal && <Modal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Signin;

