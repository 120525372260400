import React from "react"
import { CreditCard, ShoppingCart, Activity, ArrowLeft } from "lucide-react"
import ComponentPagamentoAsaas from "../../AsaasComponent/AsaasComponent"
import image from "../../../imgwebpack/NutriL.webp"

const CheckoutAcompanhamento2 = () => {
  const addOrderBump = true
  const link = "https://pay.kiwify.com.br/g4CGZlP"
  const amount = 29.99

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">
      <div className="mb-6 sm:mb-8 flex justify-center">
        <div className="w-20 h-20 sm:w-24 sm:h-24 rounded-full bg-gray-200 border-4 border-white shadow-lg flex items-center justify-center overflow-hidden">
          <img src={image || "/placeholder.svg"} alt="Avatar" className="w-full h-full object-cover" />
        </div>
      </div>

      <div className="w-full max-w-md border border-gray-300 rounded-lg">
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-4 sm:p-6">
            <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 flex items-center">
              <CreditCard className="w-5 h-5 sm:w-6 sm:h-6 mr-2 text-black" />
              Finalizar Pagamento
            </h1>
            <div className="space-y-4 sm:space-y-6">
              <div>
                <h2 className="text-base sm:text-lg font-semibold mb-2 flex items-center">
                  <ShoppingCart className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-gray-400" />
                  Resumo do Pedido
                </h2>
                <hr className="border-gray-200 my-2" />
                <div className="flex justify-between items-center text-sm mb-2">
                  <span className="flex items-center">
                    <Activity className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2 text-gray-400" />
                    Dieta + Treino
                    <span className="ml-1 sm:ml-2 text-xs bg-green-200 text-green-800 px-1 sm:px-2 py-0.5 sm:py-1 rounded-full">
                      + Consulta
                    </span>
                  </span>
                  <span className="font-medium">R$ {amount.toFixed(2)}</span>
                </div>
                <hr className="border-gray-200 my-2" />
                <div className="flex items-center text-xs sm:text-sm text-gray-500 mt-2">
                  <ArrowLeft className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" />
                  <span>Retorne ao site após o pagamento.</span>
                </div>
              </div>

              <hr className="border-gray-200" />

              <div className="payment-method-selecto">
                <ComponentPagamentoAsaas valor={amount} addOrderBump={addOrderBump} linkexterno={link} />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 sm:px-6 py-3 sm:py-4 rounded-b-lg flex justify-center">
            <p className="text-xs text-gray-500">&copy; 2025 Nutri Inteligente. Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutAcompanhamento2

