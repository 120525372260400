import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  deleteUser,
} from 'firebase/auth';
import { auth, database1 } from '../firebase';
import { get, ref, set, update} from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../services';
import { saveUserInfo,getPaymentId, removeUserInfo, removePaymentId, removePaymentStartTime } from '../helpers/localStorage.helper';
import { toast } from 'react-toastify';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  
 

  const createUser = async (email, password, numero) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    const uid = userCredential.user.uid;
    // Crie um nó no Realtime Database para armazenar informações do usuário
    const userRef = ref(database1, "users/" + uid);
    set(userRef, {
      email: email,
      Dieta: "",
      Tickets: 0,
      Numero: numero,
      lastLogin: new Date().toISOString()
    });
    setUser(userCredential.user);
    saveUserInfo(userCredential.user);
  };

  const retornaDados = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userPeso = userData.Peso;
          const userAltura = userData.Altura;
          const userPagamento = userData.DiaPagamento
          const userHorarios = userData.Horarios
          // const userTMB = userData.TMB;
          const userIMC = userData.IMC;
          const userPrompt = userData.Prompt;
          return [userPeso, userAltura, userIMC, userPrompt, userPagamento,userHorarios]; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null; // Retorna null em caso de erro
    }
  };



  const deleteAccount = async () =>{
    try{
      const current = auth.currentUser;
      deleteUser(current).then(() => {
        navigate("/")
      }).catch((error) => {
      });

      }catch{
    }
  }

  const redefinePassword = async (email) =>{
    try{
    await sendPasswordResetEmail(auth, email)
      .then(() => {
      })
      .catch((error) => {
      });
    }catch{
    }
  }

  const signIn = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
 
    setUser(userCredential.user);
    saveUserInfo(userCredential.user);
  };

  const MarcarHorario = async (data, horario, nomeUser, numeroUser) => {
    try {
      const currentUser = auth.currentUser
    const userRef = ref(database1, `users/${currentUser.uid}`);
    const snapshotUser = await get(userRef);
    const userData = snapshotUser.val();
    const email = userData.email;
      const response = await fetch(`${process.env.REACT_APP_API_URL2}/MarcarHorario`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data, horario, nomeUser, numeroUser, email })
      });

      if (response.ok) {
        toast.info('Atendimento Marcado para Dia: '+ data + ' no horario de: '+ horario )
        atendimentoNutricionista(false)
        navigate('/dieta')
          
          // Chama getUserData para obter dados atualizados do usuário
          // Faça algo com userData, como atualizar o estado ou exibir na tela
      } else {
        toast.error('Não é possível marcar horários para o dia anterior ou menos de 1 horas antes.')
        atendimentoNutricionista(true)
      }
  } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
  }
    }


  const HorariosMarcado =  async (data) => {
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL2}/HorariosMarcado`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data })
  });
  
  const dados = await response.json()
  
  return dados
}catch{console.log('error')}


    }

     
     
    const HorariosMarcadoCliente = async (email) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL2}/HorariosMarcadoCliente`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
    });
      return response.json()
    };

    const MarcarHorarioPersonalizado = async (data, horario, nomeUser, numeroUser) => {
      try {
        const currentUser = auth.currentUser
      const userRef = ref(database1, `users/${currentUser.uid}`);
      const snapshotUser = await get(userRef);
      const userData = snapshotUser.val();
      const email = userData.email;
        const response = await fetch(`${process.env.REACT_APP_API_URL2}/MarcarHorarioPersonalizado`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data, horario, nomeUser, numeroUser, email })
        });
  
        if (response.ok) {
          toast.info('Atendimento Marcado para Dia: '+ data + ' no horario de: '+ horario )
          atendimentoNutricionista(false, true)
          navigate('/dieta')
            
            // Chama getUserData para obter dados atualizados do usuário
            // Faça algo com userData, como atualizar o estado ou exibir na tela
        } else {
          toast.error('Não é possível marcar horários para o dia anterior ou menos de 1 horas antes.')
        }
    } catch (error) {
        console.error("Erro ao fazer a requisição:", error);
    }
      }
  
  
    const HorariosMarcadoPersonalizado =  async (data) => {
      try{
      const response = await fetch(`${process.env.REACT_APP_API_URL2}/HorariosMarcadoPersonalizado`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data })
    });
    
    const dados = await response.json()
    
    return dados
  }catch{console.log('error')}
  
  
      }
  
       
      const HorariosMarcadoClientePersonalizado = async (email) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL2}/HorariosMarcadoClientePersonalizado`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email })
      });
        return response.json()
      };

    const atendimentoNutricionista = async (tipo, perso) => {
      try {
        const current = auth.currentUser;
        if (current) {
          const userRef = ref(database1, `users/${current.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
              const personalizado = snapshot.val().nutricionistaPersonalizado
              const ativo = tipo
              if(perso === true){
                if(tipo === false){
                  if(personalizado > 0){
                  update(userRef, {
                    nutricionistaPersonalizado: personalizado - 1,
                  })
                }
                }else{
                  update(userRef, {
                    nutricionistaPersonalizado: 1,
                  })
                } 
              }else{
                update(userRef, {
                  nutricionista: ativo,
                })
              }
              
          }
        }
      } catch (error) {
        console.error("Erro ao ativar atendimento: ", error);
      }
        
    };

    const verificarAtendimento = async () => {
      try{
      const current = auth.currentUser;
        const userRef = ref(database1, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val(); 
          if(userData.nutricionista){
          return userData.nutricionista;
          }else{
            return false;
          }
        }
      }catch{
      
      }
    }

    
    const verificarAtendimentoPersonalizado = async () => {
      try{
      const current = auth.currentUser;
        const userRef = ref(database1, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val(); 
          if(userData.nutricionistaPersonalizado){
          return userData.nutricionistaPersonalizado;
          }else{
            return false;
          }
        }
      }catch{
      
      }
    }
    
  

  const retornaTicket = async () => {

    try {
      const current = auth.currentUser;
      if (current != null) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.Tickets;
          
          return userTickets; // Retorna o valor dos tickets
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null;
    }
  };

  const retornaTicketUsado = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userTickets = userData.TicketsUsados;
          return userTickets; // Retorna o valor dos tickets
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null;
    }
  };

  const adicionarTicket = async (tipo) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets == 0) {
            const setTicket = tipo
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
          }
        } 
      } 
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
    }
  };

  const criarPagamentoAsaas = async (valor, email, uid, addOrderBump) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/CriarPagamentoAsaas`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', 
        body: JSON.stringify({ valor, email, uid, addOrderBump })
    });
    if(response.ok){
      const dados = await response.json()
    return dados;
    }
  }catch{
      toast.error("Erro ao criar pagamento, tente novamente mais tarde.")
  }
  }

  const pagarPix = async (payload, valor) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/PagarPix`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', 
        body: JSON.stringify({ payload, valor })
    });
    if(response.ok){
      const dados = await response.json()
    return dados;
    }
  }catch{
      console.log("Erro ao criar pagamento")
  }
  }

  const CriarWebhook = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/CriarWebhookAsaas`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if(response.ok){
      const dados = await response.json()
    return dados;
    }
  }catch{
      console.log("Erro ao criar pagamento")
  }
  }

  const cancelarPagamentoAsaas = async (idPagamento) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/CancelarPagamentoAsaas`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idPagamento })
    });
    if(response.ok){
      const dados = await response.json()
    return dados;
    }
  }catch{
      console.log("Erro ao criar pagamento")
  }
  }

  

  const criarChaveAsaas = async (valor) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/CriarChave`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if(response.ok){
      const dados = await response.json()
      console.log(dados)
      console.log(dados.id)
      return dados
    }
  }catch{
      console.log("Erro ao criar pagamento")
  }
  }

  const CriarPagamento = async (amount, addordebump, email, uid, fbc, fbp) => {
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}/processarPagamento`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      credentials: 'include', 
     body: JSON.stringify({amount, addordebump, email, uid, fbc, fbp})
  });

  if (response.ok) {
    const data = await response.json()
    
    console.log(data)
    return(data)
    
  } else {
    console.log('Erro ao criar pagamento')
  }
} catch (error) {
  console.error("Erro ao fazer a requisição:", error);
}
}


const VerificarPagamento = async (PaymentID) => {
  
  try{
  const response = await fetch(`${process.env.REACT_APP_API_URL}/VerificarPagamento`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({PaymentID})
});

if (response.ok) {
  const data = await response.json()
console.log(data)
return(data)
    
    // Chama getUserData para obter dados atualizados do usuário
    // Faça algo com userData, como atualizar o estado ou exibir na tela
} else {
  console.log('Erro ao criar pagamento')
}
} catch (error) {
console.error("Erro ao fazer a requisição:", error);
}
}

const CancelarPagamento = async (PaymentID) => {
  
try{
const response = await fetch(`${process.env.REACT_APP_API_URL}/CancelarPagamento`, {
  method: 'POST',
  headers: {
      'Content-Type': 'application/json',
  },
  body: JSON.stringify({PaymentID})
});

if (response.ok) {
const data = await response.json()
console.log(data)
return(data)
  
  // Chama getUserData para obter dados atualizados do usuário
  // Faça algo com userData, como atualizar o estado ou exibir na tela
} else {
console.log('Erro ao criar pagamento')
}
} catch (error) {
console.error("Erro ao fazer a requisição:", error);
}
}
 

  const adicionarDieta = async (dietaSalva) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const setDieta = dietaSalva
          update(userRef, {
            email: current.email,
            Dieta: setDieta,
            lastLogin: new Date().toISOString()
          })

        }
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  };

  const adicionarPrompt = async (prompt, treino, genero) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
            const newPrompt = { prompt: prompt, treino: treino , genero: genero};
            update(userRef, {
              Prompt: newPrompt,
            })
          } else{
            const newPrompt = { prompt: prompt, treino: treino , genero: genero};
            set(userRef, {
              Prompt: newPrompt,
              Dieta: "",
              Tickets: 0
            })
          }
        
      }
    } catch (error) {
      adicionarErro("Erro ao salvar Prompt");
    }
  };

  const salvarDados = async (peso, altura, imc, horarios) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const setPeso = peso;
          const setAltura = altura;
          // const setTMB = tmb;
          const setImc = imc;

          update(userRef, {
            email: current.email,
            Peso: setPeso,
            Altura: setAltura,
            // TMB: setTMB,
            IMC: setImc,
            Horarios: horarios
          })
        } 
      }
    } catch (error) {
      adicionarErro("Erro ao salvar Dados");
    }
  };

  const adicionarErro = async (erro) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
            const setErro = erro
            update(userRef, {
              Erro: setErro,
            })
          }
        
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  };

  const adicionarRequest = async (erro) => {
    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
            const setErro = erro
            update(userRef, {
              Requisições: setErro,
            })
          }
        
      }
    } catch (error) {
      console.error("Erro dieta", error);
    }
  };

  const retornaDieta = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userDieta = userData.Dieta;
          return userDieta; // Retorna o valor dos tickets
        } else {
          return null; // Retorna null se o usuário não existir
        }
      } else {
        return null; // Retorna null se não houver usuário autenticado
      }
    } catch (error) {
      console.error("Erro ao recuperar Dieta:", error);
      return null; // Retorna null em caso de erro
    }
  };


  const retirarTicket = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          const Tickets = snapshot.val().Tickets
          // eslint-disable-next-line
          if (Tickets) {
            const setTicket = 0
            update(userRef, {
              email: current.email,
              Tickets: setTicket,
              lastLogin: new Date().toISOString()
            })
            navigate(location.pathname);
          } 
        } 
      }
    } catch (error) {
    }
  };
  const adicionarTicketUsado = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);


        if (snapshot.exists()) {

          if(snapshot.val().TicketsUsados){
           const Tickets = snapshot.val().TicketsUsados + 1
           update(userRef, {
            email: current.email,
            TicketsUsados: Tickets,
            lastLogin: new Date().toISOString()
          })
          // eslint-disable-next-line
        }else{
         const Tickets = 1
         update(userRef, {
          email: current.email,
          TicketsUsados: Tickets,
          lastLogin: new Date().toISOString()
        })
        }}}
    } catch (error) {
    }
  };

  const adicionarEdicaoDieta = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          if(snapshot.val().modificacao){
           update(userRef, {
            modificacao: 1,
          })
          // eslint-disable-next-line
        }else{
         update(userRef, {
          modificacao: 1,
        })
        }
      }
    }
    } catch (error) {
    }
  };

  const retirarEdicaoDieta = async () => {

    try {
      const current = auth.currentUser;
      if (current) {
        const userRef = ref(database1, `users/${current.uid}`);

        const snapshot = await get(userRef);

        if (snapshot.exists()) {

          if(snapshot.val().modificacao){
           update(userRef, {
            modificacao: 0,
          })
          // eslint-disable-next-line
        }else{
         
         update(userRef, {
          modificacao: 0,
        })
        }
      }
    }
    } catch (error) {
    }
  };

  const retornaEdicaoDieta = async () => {
    try {
      const current = auth.currentUser;
      if (current != null) {
        const userRef = ref(database1, `users/${current.uid}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const userModificacao = userData.modificacao;
          return userModificacao; // Retorna o valor dos tickets
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erro ao recuperar tickets:", error);
      return null;
    }
  };



  const logout = () => {
    
    const paypay = getPaymentId()
    if (paypay){
    api.cancelPayment(paypay, user?.accessToken);
    removePaymentStartTime();
    removePaymentId();
    }
    removeUserInfo();
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      saveUserInfo(currentUser)
      if (currentUser !== null && window.location.pathname === "/") {
        navigate('/home');
      }

    });
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <UserContext.Provider value={{ CriarPagamento, CancelarPagamento, VerificarPagamento, createUser, user,CriarWebhook, pagarPix,criarPagamentoAsaas, cancelarPagamentoAsaas, criarChaveAsaas, adicionarRequest,HorariosMarcadoPersonalizado,verificarAtendimentoPersonalizado, HorariosMarcadoClientePersonalizado, adicionarPrompt,MarcarHorarioPersonalizado, HorariosMarcadoCliente,adicionarErro, HorariosMarcado,atendimentoNutricionista, verificarAtendimento,logout,retirarEdicaoDieta, MarcarHorario, signIn,retornaEdicaoDieta, adicionarEdicaoDieta ,retornaTicketUsado, salvarDados,redefinePassword,adicionarTicketUsado, deleteAccount, retornaDados, retornaTicket, adicionarTicket, retirarTicket, adicionarDieta, retornaDieta, theme, setTheme }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
