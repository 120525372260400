import React from "react"
import { CreditCard, ShoppingCart, Activity, ArrowLeft} from "lucide-react"
import ComponentPagamentoAsaas from "../../AsaasComponent/AsaasComponent"
import image from "../../../imgwebpack/NutriL.webp"

const CheckoutEmail = () => {
  const addOrderBump = false
  const amount = 1.99

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">

      <div className="w-full max-w-md">
        <div className="mb-8 flex justify-center">
          <div className="w-24 h-24 rounded-full bg-white border-4 border-white shadow-lg flex items-center justify-center overflow-hidden">
            <img src={image} alt="Avatar" className="w-full h-full  object-cover" ></img>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-6 flex items-center">
              <CreditCard className="w-6 h-6 mr-2 text-black" />
              Finalizar Pagamento
            </h1>
            <div className="space-y-6">
              <div>
                <h2 className="text-lg font-semibold mb-2 flex items-center">
                  <ShoppingCart className="w-5 h-5 mr-2 text-gray-400" />
                  Resumo do Pedido
                </h2>
                <div className="flex justify-between items-center text-sm">
                  <span className="flex items-center">
                    <Activity className="w-4 h-4 mr-2 text-gray-400" />
                    Envio de dieta e-mail
                    {addOrderBump && (
                      <span className="ml-2 text-xs bg-gray-200 text-gray-800 px-2 py-1 rounded-full">+ Adicional</span>
                    )}
                  </span>
                  <span className="font-medium">R$ {amount.toFixed(2)}</span>
                </div>
              </div>

              <div className="flex items-center text-sm text-gray-500">
                <ArrowLeft className="w-4 h-4 mr-2" />
                <span>Retorne ao site após o pagamento.</span>
              </div>

              <hr className="border-gray-200" />

      
              <div className="payment-method-selecto">
                <ComponentPagamentoAsaas
                  valor={amount}
                  addOrderBump={addOrderBump}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-center">
            <p className="text-xs text-center text-gray-500">&copy; 2025 Nutri Inteligente. Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

// Componente simulado do Asaas para fins de demonstração


export default CheckoutEmail