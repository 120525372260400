import React from 'react';
import { WhatsAppModal } from './WhatsappOptions';
import { useNavigate } from 'react-router-dom';



const Suporte = () => {
  const navigate =  useNavigate()
  const closeModal = () => navigate('/home') 

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 px-4">
      <WhatsAppModal isOpen={true} onClose={closeModal}/>
    </div>
  );
};

export default Suporte;