import React, { useState } from 'react'
import LandingPageIMG from '../../imgwebpack/LandingPageIMG.webp'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronRight, User, ArrowRight, X, Menu, Loader } from 'lucide-react'
import Features from '../Business/Features'
import Price from '../Business/Price'
import Footer from './Footer'

export default function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleNavigation = (url) => {
    setIsLoading(true)
    // Simulate a delay before navigation
    setTimeout(() => {
      window.location.href = url
    }, 1500)
  }

  const NavigationButton = ({ href, children, className }) => (
    <motion.button
      onClick={() => handleNavigation(href)}
      className={`${className} inline-flex items-center justify-center`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader className="animate-spin mr-2 h-5 w-5" />
      ) : (
        children
      )}
    </motion.button>
  )

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white fixed top-0 left-0 right-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <nav className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h1 className="text-2xl font-bold text-gray-800">Nutri Inteligente PRO</h1>
              </motion.div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="hidden sm:flex space-x-4">
                <NavigationButton
                  href="https://nutriinteligentepro.online"
                  className="bg-green-600 text-white px-4 py-2 rounded-full font-semibold no-underline"
                >
                  Ir para o Site
                  <ArrowRight className="ml-2 h-5 w-5" />
                </NavigationButton>
              </div>
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="sm:hidden">
                <Menu className="w-6 h-6 text-gray-600" />
              </button>
            </div>
          </nav>
        </div>
      </header>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed top-0 right-0 w-full h-full bg-white shadow-md z-20 overflow-y-auto sm:hidden"
          >
            <div className="container mx-auto px-4 py-4">
              <div className="flex justify-between items-center mb-8">
                <h2 className="text-2xl font-bold text-gray-800">Menu</h2>
                <button onClick={() => setIsMenuOpen(false)}>
                  <X className="w-6 h-6 text-gray-600" />
                </button>
              </div>
              <div className="flex flex-col space-y-6">
      
                <NavigationButton
                  href="https://nutriinteligentepro.online"
                  className="bg-green-600 text-white px-6 py-2 rounded-full font-semibold text-xl"
                >
                  Login <ChevronRight className="ml-2 w-5 h-5" />
                </NavigationButton>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <main className="pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            <br></br>
            <span className="bg-green-100 text-green-600 text-xs font-semibold px-2.5 py-0.5 rounded inline-flex items-center">
              <User className="w-3 h-3 mr-1" />
              Para Nutricionistas e Personal Trainers
            </span>
            <br></br>
            <br></br>
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900 mt-6 mb-6 leading-tight tracking-tight">
              Monte Dietas em <span className="text-green-600">Segundos</span>
            </h2>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Ferramenta exclusiva para nutricionistas e personal trainers criarem planos alimentares personalizados com rapidez e precisão.
            </p>
            <NavigationButton
              href="https://nutriinteligentepro.online/signup-nutri"
              className="bg-green-600 text-white px-8 py-3 rounded-full text-lg font-semibold mb-8 no-underline"
            >
              Comece Agora <ArrowRight className="ml-2 w-5 h-5" />
            </NavigationButton>
            <div className="relative w-full max-w-2xl mx-auto">
              <img
                src={LandingPageIMG}
                alt="Nutri Inteligente Dashboard"
                className="w-full h-auto"
              />
            </div>
          </motion.div>
        </div>

        <Features />

        <Price />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            className="text-center my-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            <h3 className="text-3xl font-bold text-gray-900 mb-4">
              Rapidez e Escalabilidade
            </h3>
            <p className="text-xl text-gray-600 mb-8">
              Junte-se a milhares de profissionais que já estão usando o Nutri Inteligente PRO.
            </p>
            <NavigationButton
              href="https://nutriinteligentepro.online"
              className="bg-green-600 text-white px-8 py-3 rounded-full text-lg font-semibold"
            >
              Criar uma Conta <ArrowRight className="ml-2 w-5 h-5" />
            </NavigationButton>
          </motion.div>
        </div>
      </main>
      <Footer />
    </div>
  )
}