import { X, CreditCard, Calendar, Utensils } from 'lucide-react'

export function WhatsAppModal({ isOpen, onClose }) {
  if (!isOpen) return null

  const openWhatsApp = () => {
    const numeros = [
      "5524992178767"    ];
    const numeroAleatorio = numeros[Math.floor(Math.random() * numeros.length)];
    const whatsappURL = `https://api.whatsapp.com/send?phone=${numeroAleatorio}`;
    window.open(whatsappURL, '_blank');
  };

  const openWhatsApp2 = () => {
    const numeros = [
        "5524993288136"

    ];
    const numeroAleatorio = numeros[Math.floor(Math.random() * numeros.length)];
    const whatsappURL = `https://api.whatsapp.com/send?phone=${numeroAleatorio}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mx-auto">Suporte WhatsApp</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>
        <p className="text-gray-600 mb-6  text-left text-sm">Selecione uma opção para falar com nosso suporte:</p>
        <div className="space-y-3">
          <button onClick={openWhatsApp} className="w-full bg-green-600 text-white hover:bg-green-700 transition duration-300 ease-in-out py-3 px-4 rounded-lg flex items-center justify-start">
            <CreditCard className="h-5 w-5 mr-3" />
            <span>Paguei e não recebi</span>
          </button>
          <button onClick={openWhatsApp2} className="w-full bg-green-600 text-white hover:bg-green-700 transition duration-300 ease-in-out py-3 px-4 rounded-lg flex items-center justify-start">
            <Calendar className="h-5 w-5 mr-3" />
            <span>Agendar Consulta</span>
          </button>
          <button onClick={openWhatsApp} className="w-full bg-green-600 text-white hover:bg-green-700 transition duration-300 ease-in-out py-3 px-4 rounded-lg flex items-center justify-start">
            <Utensils className="h-5 w-5 mr-3" />
            <span>Modificar minha dieta</span>
          </button>
        </div>
      </div>
    </div>
  )
}

