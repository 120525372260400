import React, { useState, useEffect } from "react"
import { CreditCard, ShoppingCart, Dumbbell, Salad, Activity, ArrowLeft } from "lucide-react"
import ComponentPagamentoAsaas from "../../AsaasComponent/AsaasComponent"
import image from "../../../imgwebpack/NutriL.webp"

const CheckoutEmagrecer = () => {
  const [addOrderBump, setAddOrderBump] = useState(false)
  const [amount, setAmount] = useState(9.99)
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true)

  useEffect(() => {
    setShowOrderBumpOverlay(true)
  }, [])

  const handleChangeOrderBump = () => {
    const baseAmount = 9.99
    const extraAmount = 4.99
    setAddOrderBump(!addOrderBump)
    setAmount(!addOrderBump ? baseAmount + extraAmount : baseAmount)
  }

  const handleConfirmOrderBump = () => {
    setAddOrderBump(true)
    setShowOrderBumpOverlay(false)
    handleChangeOrderBump()
  }

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false)
  }

  const OrderBumpOverlay = ({ onConfirm, onCancel }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-2">Incluir Treino na Dieta?</h2>
          <p className="text-gray-600 mb-4">Por apenas R$ 4,99 a mais</p>
          <ul className="space-y-3 mb-6">
            {[
              { icon: Dumbbell, text: "Treinos personalizados" },
              { icon: CreditCard, text: "Recomendação Whey e creatina" },
              { icon: Salad, text: "Receitas fit, Lista de substituições" },
              { icon: ShoppingCart, text: "Lista de compras dos alimentos" },
            ].map((item, index) => (
              <li key={index} className="flex items-center">
                <item.icon className="w-5 h-5 mr-3 text-gray-400" />
                <span className="text-sm text-gray-800">{item.text}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-end space-x-3">
          <button className="px-4 py-2 text-gray-600 hover:text-gray-800" onClick={onCancel}>
            Não, obrigado
          </button>
          <button className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800" onClick={onConfirm}>
            Sim, quero!
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">
      {showOrderBumpOverlay && <OrderBumpOverlay onConfirm={handleConfirmOrderBump} onCancel={handleCancelOrderBump} />}

      <div className="mb-8 flex justify-center">
        <div className="w-24 h-24 rounded-full bg-gray-200 border-4 border-white shadow-lg flex items-center justify-center overflow-hidden">
          <img src={image || "/placeholder.svg"} alt="Avatar" className="w-full h-full object-cover" />
        </div>
      </div>

      <div className="w-full max-w-md border border-gray-300 rounded-lg">
        <div className="bg-white rounded-lg shadow-md">
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-6 flex items-center">
              <CreditCard className="w-6 h-6 mr-2 text-black" />
              Finalizar Pagamento
            </h1>
            <div className="space-y-6">
              <div>
                <h2 className="text-lg font-semibold mb-2 flex items-center">
                  <ShoppingCart className="w-5 h-5 mr-2 text-gray-400" />
                  Resumo do Pedido
                </h2>
                <hr className="border-gray-200 my-2" />
                <div className="flex justify-between items-center text-sm mb-2">
                  <span className="flex items-center">
                    <Activity className="w-4 h-4 mr-2 text-gray-400" />
                    Dieta Emagrecer
                    {addOrderBump && (
                      <span className="ml-2 text-xs bg-green-200 text-green-800 px-2 py-1 rounded-full">
                        + Adicional
                      </span>
                    )}
                  </span>
                  <span className="font-medium">R$ {amount.toFixed(2)}</span>
                </div>
                <hr className="border-gray-200 my-2" />
                <div className="flex items-center text-sm text-gray-500 mt-2">
                  <ArrowLeft className="w-4 h-4 mr-2" />
                  <span>Retorne ao site após o pagamento.</span>
                </div>
              </div>

              <hr className="border-gray-200" />

              <div className="payment-method-selecto">
                <ComponentPagamentoAsaas
                  valor={amount}
                  addOrderBump={addOrderBump}
                  linkexterno={"https://pay.kiwify.com.br/tjUKDUT"}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-center">
            <p className="text-xs text-gray-500">&copy; 2025 Nutri Inteligente. Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckoutEmagrecer

