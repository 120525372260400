import React, { useState, useEffect, useCallback } from "react"
import { UserAuth } from "../../context/AuthContext"
import { CreditCard } from "lucide-react"

// Função para obter cookies específicos do navegador
function getCookie(name) {
  const cookies = document.cookie.split("; ");
  const cookie = cookies.find((row) => row.startsWith(name + "="));
  return cookie ? cookie.split("=")[1] : null;
}

// Função para criar o link de pagamento com os parâmetros de rastreamento
function criarLinkPagamento(linkexterno, token, fbc, fbp, userAgent) {
  const url = new URL(linkexterno);
  url.searchParams.set("utm_content", token);
  url.searchParams.set("utm_source", token);

  if (fbc) url.searchParams.set("utm_medium", fbc);
  if (fbp) url.searchParams.set("utm_campaign", fbp);
  if (userAgent) url.searchParams.set("utm_term", encodeURIComponent(userAgent));

  return url.toString();
}

function PagamentoComponent(linkexterno) {
  const [linkPagamento, setLinkPagamento] = useState(null);
  const { user } = UserAuth();

  const updateLinkPagamento = useCallback(() => {
    if (user && user.uid) {
      const fbc = getCookie("_fbc");
      const fbp = getCookie("_fbp");
      const userAgent = navigator.userAgent; // Obtém o User-Agent do navegador

      const link = criarLinkPagamento(linkexterno.linkexterno, user.uid, fbc, fbp, userAgent);
      setLinkPagamento(link);
    } else {
      setLinkPagamento(null);
    }
  }, [user, linkexterno.linkexterno]);

  useEffect(() => {
    updateLinkPagamento();
  }, [updateLinkPagamento]);
 

  if (!linkPagamento) {
    return <p>Carregando...</p>
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="flex items-center justify-center my-4">
        <hr className="flex-grow border-gray-300" />
        <span className="px-3 text-gray-500 text-sm">Ou</span>
        <hr className="flex-grow border-gray-300" />
      </div>
      <a className="no-underline w-full block" href={linkPagamento}>
        <button className="w-full flex items-center justify-center bg-white text-black border-2 border-black px-4 py-3 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-black focus:ring-opacity-50">
          <CreditCard className="w-5 h-5 mr-2 text-black" />
          <span className="font-medium">Cartão de crédito</span>
        </button>
      </a>
    </div>
  )
}

export default PagamentoComponent

